import React from "react";
// import FA from '@fortawesome/fontawesome'
import { library as FALibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
// import FASolid from '@fortawesome/fontawesome-free-solid'
import { fas as FASolid } from '@fortawesome/free-solid-svg-icons'
// import FABrands from '@fortawesome/fontawesome-free-brands'
import { fab as FABrands } from '@fortawesome/free-brands-svg-icons'

import './index.scss'

FALibrary.add(FABrands, FASolid)

export default class SocialLink extends React.Component {
  render() {
    let social = this.props.social
    return (<nav className='social-links'>{
      social.map(l =>
        <a className='social-link' rel='me' title={l.tip} key={l.tip} href={l.url.replace('%u', l.name)}>
          <FAIcon icon={[ `${ l.fa_set || 'fas' }`, `${ l.fa_icon || l.site }` ]} />
        </a>)
    }</nav>)
  }
}
