import React from "react";
import { Link } from "gatsby"

import './index.scss'

export default class Navigation extends React.Component {
  render() {
    let nav = this.props.nav
    return (<nav className='site-navigation'>{
      nav.map(l =>
        <Link title={l.tip} to={l.url} key={l.url} activeClassName="active">
          <span>{ l.title || l.url }</span>
        </Link>)
    }</nav>)
  }
}
