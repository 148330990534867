import React from "react"

export default class Footer extends React.Component {
  render() {
    const { children } = this.props;
    return (<footer>
        <hr />
        {children}
      </footer>)
  }
}
