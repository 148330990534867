import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '@fontsource/source-code-pro'
import '@fontsource/merriweather'
import './index.scss'
import 'katex/dist/katex.css'
import '../styles/Lato/latofonts.css'

/*
import FA from '@fortawesome/fontawesome'
import FAIcon from '@fortawesome/react-fontawesome'
import FASolid from '@fortawesome/fontawesome-free-solid'
import FABrands from '@fortawesome/fontawesome-free-brands'

FA.library.add(FABrands, FASolid)
*/

export default class MainLayout extends React.Component {
  getLocalTitle(nav, location) {
    //const nav = this.props.data.site.siteMetadata.nav;
    if (location.pathname === '/') return ''
    for (const n of nav) {
      if (n.url === location.pathname) return n.title || n.url
    }
    return location.pathname
  }
  render() {
    console.log(this.props)
    //const site = this.props.data.site.siteMetadata;
    const { children, location } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query IndexQueryThing {
            site {
              siteMetadata {
                title
                author
                description
                language
                nav {
                  title
                  tip
                  url
                }
                social {
                  site
                  tip
                  url
                  name
                  fa_icon
                  fa_set
                }
              }
            }
          }
        `}
        render={ data => {
          const site = data.site.siteMetadata
          console.log(site)
          return (
            <div className='measure' lang={site.language || 'en'}>
              <Helmet>
                <title>{`${this.getLocalTitle(site.nav, location)} ${site.title}`}</title>
                <meta name="description" content={site.description} />
                <script>{[
                  "function iFrameResize(el) {",
                  "  el.style.height = `${el.contentWindow.document.documentElement.scrollHeight}px`;",
                  "}",
                ].join('\n')}</script>
              </Helmet>
              <Header site={site} />
              {children}
              <Footer>
                {site.author} - {(new Date()).getFullYear()}
              </Footer>
            </div>
          )
        }
        }
        />
    );
  }
}

/*
export const pageQuery = graphql`
  query IndexQueryThing {
    site {
      siteMetadata {
        title
        author
        description
        language
        nav {
          title
          tip
          url
        }
        social {
          site
          tip
          url
          name
          fa_icon
          fa_set
        }
      }
    }
  }
`;
*/
