import React from "react";
import { Link } from "gatsby"
import SocialLinks from "../SocialLinks"
import Navigation from "../Navigation"

export default class Footer extends React.Component {
  render() {
    let site = this.props.site
    return (<header className='site-header'>
        <Link className='site-title' title={site.description} to='/'>
          <h1>{site.title}</h1>
        </Link>
        <Navigation nav={site.nav} />
        <SocialLinks social={site.social} />
      </header>)
  }
}
